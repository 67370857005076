import React,{Component}  from "react"
import {connect} from 'react-redux'
import * as Actions from 'redux/actions'
import log from 'components/utils/Logger'
import {withRouter,Link} from 'react-router-dom'
import * as GlobalConfig from 'constants/GlobalConfig';
import {message} from 'antd';


const mapStateToProps = (state, props) => {
    return {
        errors: state.errors,
        successes: state.successes
    }
};


const mapDispatchToProps = dispatch => {
    return {
        resetError: () => {
            const reset = {error:false,message:""};
            dispatch(Actions.errors(reset))
        }
    }
};


class Message extends Component{
    constructor(props) {
        super(props);

        this.state = {
            errorData: this.props.errors,
            sec: 4,
            visible : this.props.alertModalIsOpen
        }

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors.error){
            if(!this.state.isShowingError){
                // セッション切れの場合
                if(nextProps.errors.payload.hasOwnProperty("code") && nextProps.errors.payload.code === 403){
                    this.setState({
                        isShowingError: true
                    },()=>{
                        message.error(GlobalConfig.SessionErrorMessage,this.state.sec,this.handleResetError);
                        this.props.resetError();
                        this.props.history.push(`/login`);
                    })
                    return;
                }

                // その他エラー
                if(nextProps.errors.payload.hasOwnProperty("result") && nextProps.errors.payload.result.hasOwnProperty("errors") && nextProps.errors.payload.result.errors.length !== 0) {
                    //result errorsが存在し errorsはどうみても配列の場合
                    if (nextProps.errors.payload.result.hasOwnProperty("url")) {
                        //その中でさらにurlが存在する場合
                        this.setState({
                            isShowingError: true
                        }, () => {
                            message.error(
                                <span>
                            {nextProps.errors.payload.result.errors[0].message}<br/>
                            <span style={{color: "#777777"}}>リクエストAPI:{nextProps.errors.payload.result.url}</span>
                        </span>, this.state.sec, this.handleResetError
                            );
                        })
                    } else {
                        if(nextProps.errors.payload.result.hasOwnProperty("message")) {
                            log.debug(nextProps.errors.payload);
                            this.setState({
                                isShowingError: true
                            }, () => {
                                message.error(nextProps.errors.payload.result.message, this.state.sec, this.handleResetError);
                            })
                        }
                    }
                }else if(nextProps.errors.payload.hasOwnProperty("result") && nextProps.errors.payload.result.hasOwnProperty("message")){
                    //resultが存在　messageがresult直下に存在
                    this.setState({
                        isShowingError: true
                    }, () => {
                        message.error(nextProps.errors.payload.result.message, this.state.sec, this.handleResetError);
                    })
                }else{
                    //どれにも当てはまらないエラー（存在するかどうかは疑問）
                    this.setState({
                        isShowingError: true
                    },()=>{
                        message.error(nextProps.errors.payload,2.0, this.handleResetError);
                    })
                }
            }
        }
    }

    handleResetError = () =>{
        this.setState({
            isShowingError: false
        })
        this.props.resetError();
    };


    render() {
        return (
            <span />
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Message))
